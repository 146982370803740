import React from "react";
import * as style from "../../style.module.css";

const Banner = () => {
  return (
    <>
      <div className=" bg-black text-white flex justify-center ">
        <div>
          <div className="text-center text-[1.5rem] md:text-[2.5rem] lg:text-[67px] pt-[100px] lg:pt-[257px] h-[257px] lg:h-[678px]">
            <h1 className={style.elsie} style={{ letterSpacing: ".8rem" }}>
              COLLECTIONS
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
