import React from "react";
import * as style from "../../../style.module.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ContentWrapper } from "../../../shared/components/contentWrapper.styled";

const AllCollections = () => {
  const data = useStaticQuery(graphql`
    {
      collections: allContentfulCollections {
        nodes {
          slug
          title
          subTitle
          image {
            gatsbyImage(width: 617, height: 726)
            filename
            url
          }
        }
      }
    }
  `);
  const collectionData = data.collections.nodes;
  return (
    <ContentWrapper>
      <div
        className="pl-4 pr-4 mt-[50px] mb-[126px] md:grid md:grid-cols-2
        md:gap-5 lg:grid xl:pr-[48px] xl:pl-[48px] lg:gap-10 lg:grid-cols-2
        xl:gap-5 xl:grid-cols-3"
      >
        {collectionData.map((data, index) => (
          <div key={index} className="">
            <Link to={`/collections/${data.slug}`}>
              <div className="relative mt-4  ">
                <div className=" mr-4 w-full rounded-lg">
                  {data.image.filename.includes(".gif") ? (
                    <img src={data.image.url} alt={data.title} />
                  ) : (
                    <GatsbyImage
                      className="w-full rounded-xl"
                      image={getImage(data.image)}
                      alt={data.title}
                    />
                  )}
                </div>
                <div className="absolute text-white p-6 bottom-2">
                  <div className={style.poppins}>
                    <p
                      className="text-[24px] md:text-[20px] lg:text-[30px]"
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      {data.title}
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-[16px]  md:text-[18px] lg:text-[20px]"
                      style={{
                        fontWeight: "lighter",
                        color: "whitesmoke",
                      }}
                    >
                      {data.subTitle}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </ContentWrapper>
  );
};

export default AllCollections;
