import * as React from "react";
import Banner from "../components/CollectionPage/Banner";
import Collections from "../components/HomePage/Collections/AllCollections";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const CollectionPage = () => {
  return (
    <>
      <main>
        <title>Collections</title>
        <Header />
        <Banner />
        <Collections />
        <Footer />
      </main>
    </>
  );
};

export default CollectionPage;
